import React, { useState } from "react";
import { ContentHeader } from "@app/components";




const SalesOrder=()=>{
    const [falseData,setFalseData]=useState([
        {id:1,name:"test",status:"2"}
    ])

    return(
        <div className="container-fluid">
                <ContentHeader title="Existing Incoives" />
              <section className=" bg-white p-4 rounded m-3">

              <table className="table table-hover">
          <thead className="thead- bg-primary">
            <tr>
              <th scope="col">S.No.</th>
              <th scope="col">Flavor Name </th>
              <th scope="col">Status</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {
             falseData.map((item:any,index:any) => {
              index=index+1
              
          
                
                return (
                 
                  <tr key={item.id} >
                    <td >{index}</td>
                    <td >{item.name}</td>
                    <td style={{ color: item.status === '2'? 'red': 'green'}}>{item.status==1?"Active"  :"In Active" }</td>
                      <td className="iconWapper"  >
                       
                      </td>                 
                  </tr>
                 
                )
              })
          
          }
          </tbody>
        </table>

            </section>
            </div>
    )
}


export default SalesOrder