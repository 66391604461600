import React, { useRef } from "react";
import LoadingBar from 'react-top-loading-bar'

interface progressProps {
    progress:number
    finished:any

}
const ProgressLoader=(props:progressProps)=>{
    const ref = useRef(null)

    return(
        <LoadingBar color={'#f11946'} progress={props.progress}
        // for setting progess to 0 again
    onLoaderFinished={props.finished} />
    )
}


export default ProgressLoader