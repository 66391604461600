import React from 'react'
import ContentHeader from '../components/content-header/ContentHeader'
import CodeGenerationTable from '../components/Tables/CodeGenerationTable'

function CodeGeneration() {
  return (
  <div>
    <ContentHeader title="Code Generation" />
    <CodeGenerationTable/>
  </div>
  )
}

export default CodeGeneration
