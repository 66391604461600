import React,{useState} from 'react';
import {ContentHeader} from '@components';
import ItemsTable from '@app/components/Tables/ItemsTable';

const Items = () => {
 
  return (
    <div>
      <ContentHeader title="Product Categories" />
      <ItemsTable/>
    </div>
  );
};

export default Items;
