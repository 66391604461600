import React, {Component, useEffect} from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Main from '@modules/main/Main';
import Login from '@modules/login/Login';
import Logout from '@modules/login/Logout';
// import Register from '@modules/register/Register';
import ConfirmPassword from '@modules/confirm-password/ConfirmPassword';
import ForgetPassword from '@modules/forgot-password/ForgotPassword';
import {useWindowSize} from '@app/hooks/useWindowSize';
import {calculateWindowSize} from '@app/utils/helpers';
import {useDispatch, useSelector} from 'react-redux';
import {progressLoader, setWindowSize} from '@app/store/reducers/ui';

import Dashboard from '@pages/Dashboard';
import Flavors from '@pages/Flavors';
import Sales from './pages/Sales';
import ExistingTable from './pages/ExistingTable';
import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import Invoice from './pages/profile/Invoice';
import Items from '@app/pages/Items';
import PurchaseAndOrders from './pages/Purchase&orders';
import Customers from './pages/Customers';
import UserAndRoles from './pages/UserAndRoles';
import Reportings from './pages/Reportings';
import ShippingAgent from './pages/ShippingAgent';
import AddItems from './components/Inputs/ItemsInput/AddItems';
import UpdateItems from './components/Inputs/ItemsInput/UpdateItems';
import Users from './pages/Users';
import AddFlavours from './components/Flavors/AddFlavours';
import AddCustomers from './components/customers/AddCustomers';
import AddUsers from './components/Users-Roles/AddUsers';
import AddAgents from './components/Shipping-agents/AddAgents';
import AddUserRole from './components/Users-Roles/AddUserRole';
import VeiwProducts from './components/veiw-product/VeiwProducts';
import UpdateFlavours from './components/Flavors/UpdateFlavours';
import ProgressLoader from './components/ProgressLoader/ProgressLoader';
import UpdateCustomers from './components/customers/UpdateCustomer';
import OrderDetails from './components/Invoice/OrderDetails';
import Incentives from './pages/Incentives';
import InvoiceDetails from './components/Tables/InvoiceDetails';
import ViewInvoice from './components/Tables/ViewInvoice';
import ExistingInvoice from './modules/ExistingInvoice/ExistingInvoice';
import SalesOrder from './modules/ExistingInvoice/order';
import UpdateAgents from './components/Shipping-agents/UpdateAgents';
import AgentAmount from './components/Shipping-agents/AgentAmount';
import {NewShipments} from './components/Forms/InputForms/NewShipments';
import ShipmentReceivingsTable from './components/Tables/ShipmentReceivingsTable';
import {UpdateShipments} from './components/Forms/InputForms/UpdateShipments';
import {VeiwShipments} from './components/Forms/InputForms/ViewShipment';
import UpdateUserRole from './components/Users-Roles/UpdateUserrole';
import ViewUserRole from './components/Users-Roles/ViewUserRoles';
import UpdateUser from './components/Users-Roles/UpdateUser';
import IncentiveAllocationTable from './components/Tables/IncentiveAllocationTable';
import CompanyDetails from './pages/CompanyDetails';
import EditInvoice from './pages/EditInvoice';
import Formation from './pages/Formation';
import CodeGeneration from './pages/CodeGeneration';
import AddFormation from './components/code-system/formation/AddFormation';
import AddCodeGeneration from './components/code-system/code-generation/AddCodeGeneration';
import EditFormation from './components/code-system/formation/EditFormation';
import EditCodeGeneration from './components/code-system/code-generation/EditCodeGeneration';
import { ACE_PLATFORM, BLOCKED_PATHS } from './components/constants/app';

const App = () => {
  const progressIndicator = useSelector((state: any) => {
    return state.ui.progress;
  });

  const windowSize = useWindowSize();
  const screenSize = useSelector((state: any) => state.ui.screenSize);
  const dispatch = useDispatch();
  const platform = ACE_PLATFORM

  useEffect(() => {
    const size = calculateWindowSize(windowSize.width);
    if (screenSize !== size) {
      dispatch(setWindowSize(size));
    }
  }, [windowSize]);

  const routes = [
    {
      Element: Dashboard,
      path: '/'
    },
    {
      Element: Dashboard,
      path: '/admin'
    },
    {
      Element: Items,
      path: '/admin/products/categories'
    },
    {
      Element: AddItems,
      path: '/admin/products/categories/add'
    },
    {
      Element: UpdateItems,
      path: '/admin/products/categories/edit/:id'
    },
    {
      Element: VeiwProducts,
      path: '/admin/products/categories/veiw/:id'
    },
    {
      Element: Flavors,
      path: '/admin/products/flavors'
    },
    {
      Element: AddFlavours,
      path: '/admin/products/flavors/add'
    },
    {
      Element: UpdateFlavours,
      path: '/admin/products/flavors/edit'
    },
    {
      Element: PurchaseAndOrders,
      path: '/admin/purchase-and-orders'
    },
    {
      Element: ShipmentReceivingsTable,
      path: '/admin/purchase-and-orders/shipment-receivings'
    },
    {
      Element: NewShipments,
      path: '/admin/purchase-and-orders/new-shipments'
    },
    {
      Element: VeiwShipments,
      path: '/admin/purchase-and-orders/view-shipments'
    },
    {
      Element: UpdateShipments,
      path: '/admin/purchase-and-orders/edit-shipments'
    },
    // {
    //   Element: Stocks,
    //   path: '/admin/purchase-and-orders/stocks'
    // },
    {
      Element: Sales,
      path: '/admin/sales-and-invoices/sales-allocates'
    },
    {
      Element: ExistingTable,
      path: '/admin/sales-and-invoices/existing-Invoice/:id'
    },
    {
      Element: Invoice,
      path: '/admin/sales-and-invoices/invoice'
    },
    {
      Element: OrderDetails,
      path: '/admin/sales-and-invoices/invoice/veiw'
    },
    {
      Element: Customers,
      path: '/admin/customers'
    },
    {
      Element: AddCustomers,
      path: '/admin/customers/add'
    },
    {
      Element: UpdateCustomers,
      path: '/admin/cutomers/update'
    },
    {
      Element: Incentives,
      path: '/admin/incentives/customer-incentives'
    },
    {
      Element: IncentiveAllocationTable,
      path: '/admin/incentives/incentive-allocation'
    },
    {
      Element: InvoiceDetails,
      path: '/admin/productsinfo/flavors'
    },
    {
      Element: ViewInvoice,
      path: '/admin/productsinfo/flavor/:id'
    },
    {
      Element: Users,
      path: '/admin/user-and-role/users'
    },
    {
      Element: AddUsers,
      path: '/admin/user-and-role/users/add'
    },
    {
      Element: UpdateUser,
      path: '/admin/user-and-role/users/edit'
    },
    {
      Element: UpdateUserRole,
      path: '/admin/user-and-role/userroles/edit'
    },
    {
      Element: ViewUserRole,
      path: '/admin/user-and-role/userroles/view'
    },
    {
      Element: UserAndRoles,
      path: '/admin/user-and-role/userroles'
    },
    {
      Element: CompanyDetails,
      path: '/admin/user-and-role/company-details'
    },
    {
      Element: AddUserRole,
      path: '/admin/user-and-role/userroles/add'
    },
    {
      Element: ExistingInvoice,
      path: '/admin/sales-and-invoices/sales/sales/existinginvoice'
    },
    {
      Element: SalesOrder,
      path: '/admin/sales-and-invoices/sales/sales/existinginvoice/order'
    },
    {
      Element: ShippingAgent,
      path: '/admin/shipping-agents'
    },
    {
      Element: AddAgents,
      path: '/admin/shipping-agents/add'
    },
    {
      Element: AgentAmount,
      path: '/admin/shipping-agents/amounts'
    },
    {
      Element: UpdateAgents,
      path: '/admin/shipping-agents/update'
    },
    {
      Element: Reportings,
      path: '/admin/reportings'
    },
    {
      Element: Logout,
      path: '/logout'
    },
    {
      Element: EditInvoice,
      path: '/admin/edit-invoice/:id'
    },
    {
      Element: Formation,
      path: '/admin/code-system/formation'
    },
    {
      Element: AddFormation,
      path: '/admin/code-system/formation/add'
    },
    {
      Element: EditFormation,
      path: '/admin/code-system/formation/edit/:id'
    },
    {
      Element: CodeGeneration,
      path: '/admin/code-system/code-generation'
    },
    {
      Element: AddCodeGeneration,
      path: '/admin/code-system/code-generation/add'
    },
    {
      Element: EditCodeGeneration,
      path: '/admin/code-system/code-generation/edit/:id'
    },
  ];

  const path = window.location.pathname;
  const getBasename = (path: string) => {
    if (path.includes('admin')) {
      return path.split('admin')[0];
    }

    return path.substring(0, path.lastIndexOf('/'));
  };

  const basename = getBasename(path);

  return (
    <BrowserRouter basename={basename}>
      <ProgressLoader
        finished={() => {
          dispatch(progressLoader(0));
        }}
        progress={progressIndicator}
      />
      <Routes>
        <Route path="/login" element={<PublicRoute />}>
          <Route path="/login" element={<Login />} />
        </Route>

        <Route path="/forgot-password" element={<PublicRoute />}>
          <Route path="/forgot-password" element={<ForgetPassword />} />
        </Route>

        <Route path="/confirm-password" element={<PublicRoute />}>
          <Route path="/confirm-password" element={<ConfirmPassword />} />
        </Route>

        <Route
          path="*"
          element={
            <div className="d-flex justify-content-center">
              <img
                src="/assets/404.jpg"
                className="img-fluid"
                alt="404 image"
              />
            </div>
          }
        />

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Main />}>
            {routes.map(({Element, path}: any, i: any) => {
              // POSH-NEW: Blocked path by platform
              if (BLOCKED_PATHS[platform].includes(path)) {
                return
              }

              return <Route path={path} key={i} element={<Element />} />;
            })}
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
