import React from 'react'

function LoginInput(props:any) {
  return (
    <div >
         <label>{props.label}</label>
              <div className="input-wrapper">
                <i className={props.icon}></i>
                <input onChange={props.onChange} className="input-field"  type={props.type} placeholder={props.placeholder}/>
              </div>
                <div style={{color:"red"}} >{props.errorMsg}</div>
    </div>
  )
}

export default LoginInput
