
import React from 'react';
import {ContentHeader} from '@components';
import CustomersTable from '@app/components/customers/CustomersTable';

const Customers = () => {
  return (
    <div>
      <ContentHeader title="Customers" />
        <section className=" bg-white p-4 rounded m-3">
          <CustomersTable/>
        </section>
    </div>
  );
};

export default Customers;
