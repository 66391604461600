import React from "react";

function ProductDescriptions(props: any) {
  return (
    <>
      {props.productDescription && (
        <div>
          <h5 className="font-weight-bold">Product Description:</h5>
          <p className="">{props.productDescription}</p>
        </div>
      )}
    </>
  );
}

export default ProductDescriptions;
