import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import AppButton from '../button/Button';
import ContentHeader from '../content-header/ContentHeader';
import PoshInputs from '../Forms/InputForms/Inputs';
import './AddUsers.css';
import swal from 'sweetalert';
import Select from 'react-select';
import {useDispatch} from 'react-redux';
import {setLoading} from '@app/store/reducers/ui';
import apiResponseGenerator from '@app/utils/apiResponseGenerator';

function UpdateUser() {
  let navigate = useNavigate();
  const {state} = useLocation();
  const [FirstName, setFirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [Email, setEmail] = useState('');
  const [Phone, setPhone] = useState('');
  const [Username, setUsername] = useState('');
  const [Disable, setDisabled] = useState(true);
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption]: any = useState(null);
  const [Roles, setRoles] = useState([]);
  const [userLable, setuserLable]: any = useState({});
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const UserRole_data = {
    FirstName,
    lastName,
    Email,
    Phone,
    Username
  };

  useEffect(() => {
    getRoles();
    getRoleById();
  }, []);

  useEffect(() => {
    if (
      FirstName !== '' &&
      lastName !== '' &&
      emailRegex.test(Email) &&
      Username !== ''
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [UserRole_data]);

  const getRoleById = async () => {
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: `user/show/${state}`
    });
    if (result) {
      let data = result.data;
      setuserLable({
        label: data?.role?.roleName,
        id: +data.id,
        value: +data.id
      });
      setFirstName(data.firstName);
      setlastName(data.lastName);
      setEmail(data.email);
      setPhone(data.phoneNo);
      setUsername(data.username);
    }
  };

  const getRoles = async () => {
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: 'roles/getAll'
    });
    if (result) {
      const temp = result.data;
      let response = temp.map((element: any) => {
        return (
          (element['label'] = element['roleName']),
          delete element['roleName'],
          (element['value'] = element['id'])
        );
      });
      setRoles(temp);
    }
  };

  const options = Roles;

  const updateUser = async () => {
    await swal({
      title: 'Are you sure ?',
      text: 'You want to update user ?',
      icon: 'warning',
      buttons: [true, true],
      dangerMode: true,
      closeOnClickOutside: false
    }).then(async (res) => {
      const data = {
        firstName: FirstName,
        lastName: lastName,
        email: Email,
        phoneNo: Phone,
        username: Username,
        role_id: selectedOption?.id || userLable?.id
      };

      const add_api_response = await apiResponseGenerator({
        dispatchFunction: dispatch,
        method: 'post',
        url: `user/update/${state}`,
        body: data
      });

      if (add_api_response) {
        dispatch(setLoading(false));
        swal({
          title: 'Success',
          text: 'User information updated.',
          icon: 'success'
        });
        navigate('/admin/user-and-role/users/');
      }
    });
  };

  return (
    <div>
      <ContentHeader title="Update User" />
      <section className=" bg-white p-4 rounded m-3">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="inputWrapper">
            <PoshInputs
              title="First Name"
              value={FirstName}
              onChange={(e: any) => {
                setFirstName(e.target.value);
              }}
              type={'text'}
              readOnly={true}
              validate={"*"}
            />
            <PoshInputs
              value={lastName}
              title="Last Name"
              onChange={(e: any) => {
                setlastName(e.target.value);
              }}
              type={'text'}
              readOnly={true}
              validate={"*"}
            />
          </div>

          <div className="inputWrapper">
            <PoshInputs
              value={Email}
              title="Email"
              onChange={(e: any) => {
                setEmail(e.target.value);
              }}
              type={'email'}
              readOnly={true}
              validate={"*"}
            />
            <PoshInputs
              value={Phone}
              title="Phone"
              onChange={(e: any) => {
                setPhone(e.target.value);
              }}
              type={'number'}
              readOnly={true}
              validate={"*"}
              hideControl
            />
          </div>

          <div className="inputWrapper">
            <PoshInputs
              title="Username"
              value={Username}
              onChange={(e: any) => {
                setUsername(e.target.value);
              }}
              type={'text'}
              readOnly={true}
              validate={"*"}
            />
            <div className="col-md-6 form-group" style={{marginTop:"10px; "}}>
              <label htmlFor="validationCustom03" className="form-label d-flex">
                User Role<span style={{color:"red",marginTop:"-1px"}}>*</span>
              </label>
              <Select
                value={selectedOption || userLable}
                onChange={(e: any) => {
                  setSelectedOption(e);
                }}
                options={options}
                placeholder="Select User Role"
              />
            </div>
          </div>

          <div
            className="d-flex justify-content-end"
            style={{marginRight: '7px'}}
          >
            <AppButton
              children={'Cancel'}
              className="btn btn-light mr-3"
              onClick={() => {
                navigate('/admin/user-and-role/users/');
              }}
            />
            <AppButton
              children={'Update'}
              disabled={Disable}
              onClick={() => {
                updateUser();
              }}
            />
          </div>
        </form>
      </section>
    </div>
  );
}

export default UpdateUser;
