import React, { useEffect, useState } from 'react';
import { ContentHeader } from '@app/components';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { ACE_PLATFORM, BASE_URL } from '@app/components/constants/app';
import swal from 'sweetalert';
import AppButton from '@app/components/button/Button';
import { ScaleLoader } from 'react-spinners';
import { downloadExcel } from 'react-export-table-to-excel';
import jsPDF from 'jspdf';
import apiResponseGenerator from '@app/utils/apiResponseGenerator';
import moment from 'moment';
import { toFixedWithoutRounding } from '@app/utils/calculationFun';
// import 'jquery/dist/jquery.min.js';
// import $ from 'jquery';
// import 'datatables.net';
// import 'datatables.net-dt/js/dataTables.dataTables';
// import 'datatables.net-dt/css';
// import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';

function Invoice() {
  const param: any = useParams();
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.auth.token);
  const [InvoiceData, setInvoiceData] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [FilterBtn, setFilterBtn] = useState<any>(true);
  const [startDate, setStartDate] = useState<any>('');
  const [endDate, setEndDate] = useState<any>('');
  let local: any = localStorage.getItem('userInfo');
  const permissionsArray: any = JSON.parse(local).permissions;
  let navigate = useNavigate();
  const platform: string = ACE_PLATFORM

  const config = {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`
  };

  useEffect(() => {
    getAll();
  }, []);

  const handleInputEmail = async (id: number) => {
    const response = await swal({
      title: 'Add invoice no',
      content: {
        element: 'input',
        attributes: {
          type: 'text',
          placeholder: 'Enter your invoice no',
        },
      },
      buttons: {
        confirm: {
          text: 'OK',
          closeModal: true,
        },
        cancel: true,
      },
      closeOnEsc: false,
      closeOnClickOutside: false,
    });

    if (response) {
      const api_response = await apiResponseGenerator({
        dispatchFunction: dispatch,
        method: 'post',
        url: `invoice/addInvoiceNo/${id}`,
        body: { invoice_id: response }
      });
      if (api_response) {
        const result = await swal({
          title: 'Success',
          text: 'Invoice no added successfully.',
          icon: 'success'
        });
        if (result) {
          navigate(0)
        }
      }
    }
  };

  const handleInvoicesReopen = async (id: number) => {

    const modalResponse = await swal({
      title: 'Are you sure?',
      text: 'you want to re-open this invoice',
      buttons: {
        confirm: {
          text: 'Yes re-open',
          closeModal: true,
        },
        cancel: true,
      },
      closeOnEsc: false,
      closeOnClickOutside: false,
      dangerMode: true
    });

    if (modalResponse) {
      const api_response = await apiResponseGenerator({
        dispatchFunction: dispatch,
        method: 'put',
        url: `invoice/reOpenByID/${id}`,
        body: { status: 0 }
      });
      if (api_response) {
        const result = await swal({
          title: 'Success',
          text: 'Invoice no added successfully.',
          icon: 'success'
        });
        if (result) {
          navigate(0)
        }
      }
    }

  }

  const getAll = async () => {
    setLoading(true);
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: `invoice/getAll${platform === "B"? "?type=P-2" : ""}` // POSH-NEW
    });

    if (result) {
      setLoading(false);

      const response = result.data;

      const tableData = response.map((item: any, index: number) => {
        let momentUTC = moment.utc(item.created_at);

        return {
          index: index + 1,
          id: item.id,
          Company_Name: item.companyName,
          invoice_id: item.invoice_id,
          Allocation_Date: momentUTC.format('MM/DD/YYYY'),
          Total_Cases: item.total_cases,
          Total_price: `$${toFixedWithoutRounding(item.grand_total - item?.discount, 2)}`,
          Status: item.status === 'Completed' ? 'Closed' : item.status
        };
      });
      setInvoiceData(tableData)
      setTimeout(() => {
        $('#example').DataTable({
          // responsive: true,
          columnDefs: [
            {
              "targets": "_all",
              "defaultContent": "-"
            }
          ],
          data: tableData,
          columns: [
            { data: 'index' },
            { data: 'Company_Name' },
            { data: 'id' },
            { data: 'invoice_id' },
            { data: 'Allocation_Date' },
            { data: 'Total_Cases' },
            { data: 'Total_price' },
            { data: 'Status' },
            {
              title: 'Action',
              render: function (data, type, row, meta) {
                const viewIcon = `
                <img src="/assets/eye.svg"
                 data-toggle="tooltip"
                 data-html="true"
                 id="veiw"
                 data-bs-placement="top"
                 title="View" height="600"
                 >`;

                const addIcon = `
                <img src="/assets/add.svg"
                 data-toggle="tooltip"
                 data-html="true"
                 id="add"
                 data-bs-placement="top"
                 title="Add" height="600"
                 >`;

                const deleteIcon = `<img src="/assets/delete.svg"
                data-toggle="tooltip"
                data-html="true"
                data-bs-placement="top"
                id="delete"
                title="Delete" height="600"
                >`;

                // let editIcon = '';

                // if (row.Status && row.Status.toLowerCase() !== 'generated') {
                //   editIcon = `<img src="/assets/edit.svg"
                //   data-toggle="tooltip"
                //   id="edit"
                //   data-html="true"
                //   data-bs-placement="top"
                //   title="Edit" height="600"
                //   >`;
                // }

                // let reOpenIcon = ``;
                // if (row.Status && row.Status.toLowerCase() !== 'generated') {
                //   reOpenIcon = `<img src="/assets/re-open.png"
                //   data-toggle="tooltip"
                //   data-html="true"
                //   data-bs-placement="top"
                //   id="re-open"
                //   title="re-open" height="600"
                //   >`;
                // }

                let actionIcons = `<div class='iconWapper'> ${addIcon} ${viewIcon} ${deleteIcon} </div>`;
                return actionIcons;
              },

              searchable: false,
              orderable: false
            }
          ]
        });

        const table: any = document.querySelector('#example');
        table && table.removeAttribute('style');

        $(document).ready(function () {
          let table = $('#example').DataTable();
          $('#example tbody').on('click', '#add', function () {
            let data = table.row($(this).parents('tr')).data();
            handleInputEmail(+data.id)
          });
          $('#example tbody').on('click', '#veiw', function () {
            let data = table.row($(this).parents('tr')).data();
            const obj = {
              id: data.id,
              invoice_id: data.invoice_id,
              state: true,
              status: data.Status
            };
            navigate(`/admin/productsinfo/flavor/${+data.id}`, { state: obj });
          });

          // $('#example tbody').on('click', '#edit', function () {
          //   let data = table.row($(this).parents('tr')).data();
          //   const obj = {
          //     id: data.id,
          //     invoice_id: data.invoice_id,
          //     state: true,
          //     status: data.Status
          //   };
          //   navigate(`/admin/edit-invoice/${+data.id}`, {state: obj});
          // });

          // $('#example tbody').on('click', '#re-open', function () {
          //   let data = table.row($(this).parents('tr')).data();
          //   handleInvoicesReopen(+data.id)

          // });

          $('#example tbody').on('click', '#delete', function () {
            let data = table.row($(this).parents('tr')).data();
            data.Status === 'Generated'
              ? deleteInvoice(+data.id)
              : RevertTheInvoice(+data.id);
          });
        });
      }, 1000);
    }
  };

  useEffect(() => {
    if (startDate !== '' && endDate !== '') {
      $('#example').DataTable().destroy();
      setFilterBtn(false);
    } else {
      setFilterBtn(true);
    }
  }, [startDate, endDate]);

  const dateFilter = async () => {
    const result = await axios({
      url: `${BASE_URL}invoice/search`,
      method: 'get',
      headers: config,
      params: {
        to: endDate,
        from: startDate
      }
    });
    if (result) {
      const response = result.data.data;

      if (response.lenggth === 0) return;

      const tableData = response.map((item: any, index: number) => {
        return {
          index: index + 1,
          id: item.id,
          Company_Name: item.companyName,
          invoice_id: item.invoice_id,
          Allocation_Date: moment(item.created_at).format('MM/DD/YYYY'),
          Total_Cases: item.total_cases,
          Total_price: `$${toFixedWithoutRounding(item.grand_total - item?.discount, 2)}`,
          Status: item.status === 'Completed' ? 'Closed' : item.status
        };
      });

      setTimeout(() => {
        $('#example').DataTable({
          data: tableData,
          columns: [
            { data: 'index' },
            { data: 'Company_Name' },
            { data: 'id' },
            { data: 'invoice_id' },
            { data: 'Allocation_Date' },
            { data: 'Total_Cases' },
            { data: 'Total_price' },
            { data: 'Status' },
            {
              title: 'Action',
              render: function (data, type, row, meta) {
                const viewIcon = `
                <img src="/assets/eye.svg"
                 data-toggle="tooltip"
                 data-html="true"
                 id="veiw"
                 data-bs-placement="top"
                 title="View" height="600"
                 >`;

                const addIcon = `
                <img src="/assets/add.svg"
                 data-toggle="tooltip"
                 data-html="true"
                 id="add"
                 data-bs-placement="top"
                 title="Add" height="600"
                 >`;

                const deleteIcon = `<img src="/assets/delete.svg"
                data-toggle="tooltip"
                data-html="true"
                data-bs-placement="top"
                id="delete"
                title="Delete" height="600"
                >`;

                let editIcon = '';

                // if (row.Status && row.Status.toLowerCase() !== 'generated') {
                //   editIcon = `<img src="/assets/edit.svg"
                //   data-toggle="tooltip"
                //   id="edit"
                //   data-html="true"
                //   data-bs-placement="top"
                //   title="Edit" height="600"
                //   >`;
                // }
                // ${editIcon} 


                // let reOpenIcon = ``;
                // if (row.Status && row.Status.toLowerCase() !== 'generated') {
                //   reOpenIcon = `<img src="/assets/re-open.png"
                //   data-toggle="tooltip"
                //   data-html="true"
                //   data-bs-placement="top"
                //   id="re-open"
                //   title="re-open" height="600"
                //   >`;
                // }

                let actionIcons = `<div class='iconWapper'> 
                ${addIcon} 
                ${viewIcon} 
                ${deleteIcon} 
                </div>`;
                return actionIcons;
              },

              searchable: false,
              orderable: false
            }
            // {
            //   title: 'Action',
            //   render: () => {
            //     const returnObj = `<div class='iconWapper'>
            //       <img src="/assets/eye.svg"
            //        data-toggle="tooltip"
            //        data-html="true"
            //        id="veiw"
            //        data-bs-placement="top"
            //        title="View" height="600"
            //        >

            //        <img src="/assets/edit.svg"
            //        data-toggle="tooltip"
            //        id="edit"
            //        data-html="true"
            //        data-bs-placement="top"
            //        title="Edit" height="600"
            //        >

            //        <img src="/assets/delete.svg"
            //        data-toggle="tooltip"
            //        data-html="true"
            //        data-bs-placement="top"
            //        id="delete"
            //        title="Delete" height="600"
            //        >

            //       </div>`;

            //     return returnObj;
            //   },

            //   searchable: false,
            //   orderable: false
            // }
          ]
        });

        const table: any = document.querySelector('#example');
        table.removeAttribute('style');

        $(document).ready(function () {
          var table = $('#example').DataTable();
          $('#example tbody').on('click', '#add', function () {
            let data = table.row($(this).parents('tr')).data();
            handleInputEmail(+data.id)
          });

          $('#example tbody').on('click', '#veiw', function () {
            var data = table.row($(this).parents('tr')).data();
            const obj = {
              id: data.id,
              invoice_id: data.invoice_id,
              state: true,
              status: data.Status
            };
            navigate(`/admin/productsinfo/flavor/${+data.id}`, { state: obj });
          });

          // $('#example tbody').on('click', '#edit', function () {
          //   var data = table.row($(this).parents('tr')).data();
          //   const obj = { 
          //     id: data.id,
          //     invoice_id: data.invoice_id,
          //     state: true,
          //     status: data.Status
          //   };
          //   navigate(`/admin/edit-invoice/${+data.id}`, {state: obj});
          // });

          // $('#example tbody').on('click', '#re-open', function () {
          //   let data = table.row($(this).parents('tr')).data();
          //   handleInvoicesReopen(+data.id)
          // });

          $('#example tbody').on('click', '#delete', function () {
            var data = table.row($(this).parents('tr')).data();
            data.Status === 'Generated'
              ? deleteInvoice(+data.id)
              : RevertTheInvoice(+data.id);
          });
        });
      }, 1000);
    }
  };

  const PdfGenerate = () => {
    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'portrait'; // portrait or landscape;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(20);

    const rows = InvoiceData?.map((item: any, index: any) => {
      return [
        item.index,
        item.Company_Name,
        item.id,
        item.invoice_id,
        item.Allocation_Date,
        item.Total_Cases,
        item.Total_price,
        item.Status
      ];
    });

    const headers = [
      [
        'S.No.',
        'Company Name',
        'Grn #',
        'Invoice #',
        'Allocation Date',
        'Total Cases',
        'Total Price',
        'Status'
      ]
    ];
    let content = {
      startY: 60,
      head: headers,
      body: rows
    };
    // @ts-ignore
    doc.autoTable(content);
    doc.save(`${new Date().toLocaleDateString('en-us')} Invoice Table.pdf`);
  };

  const ExcelGenerate: any = () => {
    const Heading: any = [
      'S.No.',
      'Company Name',
      'Grn #',
      'Invoice #',
      'Allocation Date',
      'Total Cases',
      'Total Price',
      'Status'
    ];
    const rows: any = InvoiceData?.map((item: any, index: any) => {
      return [
        item.index,
        item.Company_Name,
        item.id,
        item.invoice_id,
        item.Allocation_Date,
        item.Total_Cases,
        item.Total_price,
        item.Status
      ];
    });

    downloadExcel({
      fileName: `${new Date().toLocaleDateString('en-us')} Invoice Table`,
      sheet: `${new Date().toLocaleDateString('en-us')} Invoice Table`,
      tablePayload: {
        header: Heading,
        body: rows
      }
    });
  };

  const RevertTheInvoice = async (id: number) => {
    const model = await swal({
      title: 'Are you sure ?',
      text: 'You want to do the invoice reversal ?',
      icon: 'warning',
      buttons: ['Cancel', 'Ok'],
      dangerMode: true,
      closeOnClickOutside: false
    });

    if (model) {
      const result = await apiResponseGenerator({
        dispatchFunction: dispatch,
        method: 'delete',
        url: `invoice/reversal/${id}`
      });

      if (!result) return;
      swal({
        title: "Success",
        text: 'Invoice reversed successfully.',
        icon: 'success'
      });

      $('#example').DataTable().destroy();
      getAll();
    }
  };

  const deleteInvoice = async (id: number) => {
    const model = await swal({
      title: 'Are you sure ?',
      text: `Stock will be updated when you delete the invoice.
             You want to do the invoice reversal ?`,
      icon: 'warning',
      buttons: ['Cancel', 'Ok'],
      dangerMode: true,
      closeOnClickOutside: false
    });

    if (model) {
      const result = await apiResponseGenerator({
        dispatchFunction: dispatch,
        method: 'delete',
        url: `invoice/delete/${id}`
      });

      if (!result) return;

      swal({
        text: 'invoice deleted successfully',
        icon: 'success'
      });

      $('#example').DataTable().destroy();
      getAll();
    }
  };

  return (
    <div>
      <>
        <ContentHeader title="Invoices" />
        <section className=" bg-white p-4 rounded m-3">
          <div>
            <div className="row mb-2 d-flex  col-lg-justify-content-between">
              {/* <div id="searchInput" className="col-xxl-3">
                <SearchInput
                  onChange={(e: any) => handleSearch(e)}
                  placeholder="Company or Invoice"
                />
              </div> */}

              <div className="col-xl-3">
                <div className="input-group input-group-lg mb-3">
                  <input
                    value={startDate}
                    type="date"
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                    className="form-control"
                    aria-label="From"
                    aria-describedby="inputGroup-sizing-sm"
                  />
                </div>
              </div>

              <div className="col-xl-3">
                <div className="input-group input-group-lg mb-3">
                  <input
                    type="date"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                    className="form-control"
                    aria-label="From"
                    aria-describedby="inputGroup-sizing-sm"
                  />
                </div>
              </div>

              <div
                className="col d-flex justify-content-end"
                style={{ left: '13px' }}
              >
                <AppButton
                  children={'Apply Filter'}
                  disabled={FilterBtn}
                  className="btn-lg mb-3 mr-3 btn btn-secondary "
                  onClick={() => {
                    dateFilter();
                  }}
                />

                <div className="btn-group">
                  <div className="dropdown">
                    <button
                      className="btn btn-dark mr-3 btn-lg mb-3  dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Export
                    </button>

                    <div
                      className="dropdown-menu col-2 ExportDropdown dropdownImg"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <div
                        className="dropdown-item d-flex flex-row"
                        onClick={() => {
                          ExcelGenerate();
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <img
                          src="/assets/excel.png"
                          className="mx-2"
                          style={{
                            height: '30px',
                            width: '30px',
                            margin: 0
                          }}
                        />
                        Excel
                      </div>
                      <div className="dropdown-divider"></div>
                      <div
                        onClick={() => {
                          PdfGenerate();
                        }}
                        className="dropdown-item d-flex flex-row "
                        style={{ cursor: 'pointer' }}
                      >
                        <img
                          src="/assets/pdf.png"
                          className="mx-2"
                          style={{
                            height: '30px',
                            width: '30px',
                            margin: 0
                          }}
                        />
                        PDF
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {loading ? (
            <div>
              <div className="d-flex justify-content-center">
                <ScaleLoader color="#007bff" height={40} />
              </div>
            </div>
          ) : (
            <>
              <table id="example" className="table table-hover">
                <thead className="thead- bg-primary">
                  <tr>
                    <th scope="col">S.No.</th>
                    <th scope="col">Company Name</th>
                    <th scope="col">Grn #</th>
                    <th scope="col">Invoice #</th>
                    <th scope="col">Allocation Date</th>
                    <th scope="col">Total Cases</th>
                    <th scope="col">Total Price</th>
                    <th scope="col">Status</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
              </table>
            </>
          )}
        </section>
      </>
    </div>
  );
}

export default Invoice;
