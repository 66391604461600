import React from 'react'
import { InputGroup , Form } from 'react-bootstrap';

interface Searchprops {
    placeholder : string,
    onChange?:any,
    value?:any
}

function SearchInput(props:Searchprops) {
  return (
    <div>
        <InputGroup onChange={props.onChange} className="input-group-lg mb-3" >
            <Form.Control  placeholder={props.placeholder} />
            <InputGroup.Text style={{margin:"0px" , backgroundColor:"white"}}>
              <img src="/assets/searchIcon.png" style={{width:"20px ",height:"20px",marginBottom:"-30px" , marginTop:"-30px"}}/>
            </InputGroup.Text>
          </InputGroup>
    </div>
  )
}

export default SearchInput