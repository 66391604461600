import React from "react";
import "./flavors.css";

function Flavors({ flavourName }: any) {
  return (
    <div>
      {flavourName?.length > 1 && (
        <h5 className="font-weight-bold" style={{ marginBottom: "10px" }}>
          Flavors :{" "}
        </h5>
      )}

      <div className="d-flex flex-wrap mt-3 gap-2">
        {flavourName.map(({ flavourName }: any, i: any) => {
          return (
            <span key={i} className="flavors">
              {flavourName}
            </span>
          );
        })}
      </div>
    </div>
  );
}

export default Flavors;
