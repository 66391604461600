
import React from 'react';
import UserRolesTable from '@app/components/Tables/user&rolesTable';

const UserAndRoles = () => {
 return (
    <div>
        <UserRolesTable />
    </div>
  );
};

export default UserAndRoles;
