import React, {useEffect, useLayoutEffect, useState} from 'react';
import {ContentHeader} from '@app/components';
import AppButton from '@app/components/button/Button';
import PoshInputs from '@app/components/Forms/InputForms/Inputs';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import swal from 'sweetalert';
import Select from 'react-select';

function AddCodeGeneration() {
  const navigation = useNavigate();
  const [displayBoxes, setDisplayBoxes] = useState<number>()
  const [selectedOption, setSelectedOption]: any = useState(null);
  const [devicePerBoxes, setDevicePerBoxes] = useState<number>()
  const [Disable, setDisabled] = useState(true);
  const arrayOfObjects = [
    {
      id: 1,
      label: "13",
      value: 1
    },
    {
      id: 2,
      label: "14",
      value: 2
    },
    {
      id: 3,
      label: "15",
      value: 3
    },
    {
      id: 4,
      label: "16",
      value: 4
    }
  ];
  
  useEffect(() => {
    if (selectedOption && displayBoxes ){
      setDisabled(false)
    }else{
      setDisabled(true)
    }
    
  }, [selectedOption,displayBoxes])

 const onSubmit = async() => {
  const model = await swal({
    title: 'Are you sure ?',
    text: 'You want to add code generation ?',
    icon: 'warning',
    buttons: [true, true],
    dangerMode: true,
    closeOnClickOutside: false
  });

  if (!model) return;
  navigation(-1)
 }
  return (
    <div>
      <ContentHeader title="Add Code Generation" />
      <section className=" bg-white p-4 rounded m-3">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="inputWrapper">
            <div className="col-md-6" style={{marginTop: '-18px'}}>
            <label htmlFor="validationCustom03" className="form-label mb-1 d-flex">
              Formation<span style={{color:"red", marginTop:"1px"}}>*</span>
            </label>
            <Select
              value={selectedOption}
              onChange={(e: any) => {
                setSelectedOption(e); 
              }}
              options={arrayOfObjects}
              placeholder="Select formation no"
            />
          </div>
            <PoshInputs
              title="No of codes"
              validate={"*"}
              onChange={(e: any) => {
                setDisplayBoxes(e.target.value);
              }}
              value={displayBoxes}
              type={'number'}
              hideControl
              suggestedNo={"993889"}
              readOnly={true}
            />
          </div>
          <div className="inputWrapper">
            <PoshInputs
              title="No of complete cases"
              validate={"*"}
              onChange={(e: any) => {
                setDevicePerBoxes(e.target.value);
              }}
              value={"3088"}
              type={'number'}
              hideControl
              readOnly={true}
              disabled={true}
            />
            <PoshInputs
              disabled={true}
              title="Remaining extra codes"
              validate={"*"}
              value={"12"}
              type={'number'}
              hideControl
              readOnly={false}
            />
          </div>
          <div
            className="d-flex justify-content-end"
            style={{marginRight: '7px'}}
          >
            <AppButton
              children={'Cancel'}
              className="btn btn-light mr-3"
              onClick={() => {
                navigation(-1);
              }}
            />
            <AppButton
              children={'Generate'}
              disabled={Disable}
              onClick={() => {
                onSubmit()
              }}
            />
          </div>
        </form>
      </section>
    </div>
  );
}

export default AddCodeGeneration;
