import {createSlice} from '@reduxjs/toolkit';

export interface AuthState {
  isLoggedIn: boolean;
  token: string | null;
  currentUserInfo: any;
  permissions: any[]
}

const initialState: AuthState = {
  isLoggedIn: !!JSON.parse(localStorage.getItem('userInfo') || "{}")?.token,
  token:  JSON.parse(localStorage.getItem('userInfo') || "{}")?.token,
  currentUserInfo: JSON.parse(localStorage.getItem('userInfo') || "{}"),
  permissions:JSON.parse(localStorage.getItem('userInfo') || "{}")?.token,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginUser: (state, {payload}) => {
      const setUserInfoObj = {
        token: payload.token,
        userName: payload.username,
        role: payload.role,
        permissions: payload.permissions
      }
      localStorage.setItem('userInfo', JSON.stringify(setUserInfoObj));
      state.isLoggedIn = true;
      state.token = payload.token;
    },
    logoutUser: (state) => {
      localStorage.removeItem('userInfo');
      state.currentUserInfo = {};
      state.isLoggedIn = false;
      state.token = null;
    },
    loadUser: (state, {payload}) => {
      state.currentUserInfo = payload;
    }
  }
});

export const {loginUser, logoutUser, loadUser} = authSlice.actions;

export default authSlice.reducer;
