import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {MenuItem} from '@components';
import '@fontsource/poppins';
import { ACE_PLATFORM, BLOCKED_MENU } from '@app/components/constants/app';

export interface IMenuItem {
  id?: number;
  name: string;
  path?: string;
  img?: string;
  children?: Array<IMenuItem>;
}

export const MENU: IMenuItem[] = [
  {
    id: 1,
    name: 'menusidebar.label.dashboard',
    img: '/assets/Dashboard.svg',
    path: '/admin'
  },
  {
    id: 2,
    name: 'Products',
    img: '/assets/Products.svg',
    children: [
      {
        name: 'Product Categories',
        path: '/admin/products/categories'
      },
      {
        name: 'Flavors',
        path: '/admin/products/flavors'
      }
    ]
  },
  {
    id: 3,
    name: 'Purchase Orders',
    img: '/assets/PurchaseOrders.svg',
    path: '/admin/purchase-and-orders',
    children: [
      {
        name: 'Shipment & Receiving',
        path: '/admin/purchase-and-orders/shipment-receivings'
      }
    ]
  },
  {
    id: 4,
    name: 'Allocation & Invoices',
    img: '/assets/SalesInvoices.svg',
    children: [
      {
        name: 'Sales Allocation',
        path: '/admin/sales-and-invoices/sales-allocates'
      },
      {
        name: 'Invoices',
        path: '/admin/sales-and-invoices/invoice'
      }
    ]
  },
  {
    id: 5,
    name: 'Customers',
    path: '/admin/customers',
    img: '/assets/Customers.svg'
  },
  {
    id: 6,
    name: 'Incentives',
    img: '/assets/Incentives.svg',
    children: [
      {
        name: 'Customer Incentives',
        path: '/admin/incentives/customer-incentives'
      },
      {
        name: 'Incentive Allocation',
        path: '/admin/incentives/incentive-allocation'
      }
    ]
  },
  {
    id: 7,
    name: 'Users & Roles',
    img: '/assets/UserRoles.svg',
    children: [
      {
        name: 'Users',
        path: '/admin/user-and-role/users'
      },
      {
        name: 'User Roles',
        path: '/admin/user-and-role/userroles'
      },
      {
        name: 'Company Details',
        path: '/admin/user-and-role/company-details'
      }
    ]
  },
  {
    id: 8,
    name: 'Shipping Agents',
    img: '/assets/ShippingAgents.svg',
    path: '/admin/shipping-agents'
  },
  {
    id: 9,
    name: 'Code System',
    img: '/assets/code-system.svg',
    children: [
      {
        name: 'Formation',
        path: '/admin/code-system/formation'
      },
      {
        name: 'Code Generation',
        path: '/admin/code-system/code-generation'
      }
    ]
  },
  {
    id: 10,
    name: 'Reports',
    img: '/assets/Reports.svg',
    path: '/admin/reportings'
  },
  {
    id: 11,
    name: 'Logout',
    img: '/assets/logout.svg',
    path: '/logout'
  }
];

const MenuSidebar = () => {
  const sidebarSkin = useSelector((state: any) => state.ui.sidebarSkin);
  const menuItemFlat = useSelector((state: any) => state.ui.menuItemFlat);
  const menuChildIndent = useSelector((state: any) => state.ui.menuChildIndent);
  const [newPermissions, setnewPermissions]: any = useState([]);

  useEffect(() => {
    let local: any = localStorage.getItem('userInfo');
    const resp = JSON.parse(local).permissions;

    const array: any = [...resp];
    array.push({id: 11, title: 'Logout', view: true});
    setnewPermissions(array);
  }, []);

  return (
    <aside className={`main-sidebar elevation-4 ${sidebarSkin}`}>
      <Link to="/admin" className="brand-link p-0">
        <div>
          <img
            src="/assets/logo.svg"
            alt="NOW-POSH"
            className="brand-image "
            style={{
              marginTop: '0px',
              marginLeft: '24px',
              filter: 'invert(2)'
            }}
          />
        </div>
        <div>
          <p
            className="brand-text text-white"
            style={{fontFamily: 'poppins', marginTop: '11px', fontWeight: 500}}
          >
            NOW POSH
          </p>
        </div>
      </Link>
      <div className="sidebar">
        <div style={{display: 'flex'}}>
          <div
            className="user-panel mt-3 pb-3  d-flex"
            style={{borderBottom: 'none'}}
          >
            <div style={{flexDirection: 'row'}} className="image">
              <img
                src={'/img/default-profile.png'}
                className="img-circle elevation-2 m-0"
                alt="User"
              />
            </div>
            <span
              className={`d-block text-light ${sidebarSkin}`}
              style={{marginLeft: 13}}
            >
              {`
                ${JSON.parse(localStorage.getItem('userInfo') || '{}')?.userName}  (${JSON.parse(localStorage.getItem('userInfo') || '{}')?.role})
              `}
            </span>
          </div>
        </div>
        <nav className="mt-2">
          <ul
            style={{marginTop: -15}}
            className={`nav nav-pills nav-sidebar ${
              menuItemFlat ? ' nav-flat' : ''
            }${menuChildIndent ? ' nav-child-indent' : ''}`}
            role="menu"
          >
            {newPermissions?.map((Items: any) =>
              MENU.map((menuItem: IMenuItem) => {
                // POSH-NEW: Blocked menu by platform
                const platform = ACE_PLATFORM

                if (BLOCKED_MENU[platform].includes(menuItem.name)) {
                  return null
                }

                return Items.id === menuItem.id && Items.view ? (
                  <MenuItem key={menuItem.id} menuItem={menuItem} />
                ) : null
              }
              )
            )}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default MenuSidebar;
