import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppButton from "../button/Button";
import ContentHeader from "../content-header/ContentHeader";
import Flavors from "./flavors/Flavors";
import Image from "./Image/Image";
import ProductDescriptions from "./product-description/ProductDescriptions";
import { useDispatch, useSelector } from "react-redux";
import { ScaleLoader } from "react-spinners";
import apiResponseGenerator from "@app/utils/apiResponseGenerator";

function VeiwProducts() {
  const loading = useSelector((state: any) => state.ui.loading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {id}:any = useParams()
  const [productName, setproductName] = useState("");
  const [productSKU, setproductSKU] = useState("");
  const [volume, setVolume] = useState("");
  const [productImage, setproductImage] = useState("");
  const [productDescription, setproductDescription] = useState("");
  const [status, setstatus] = useState("");
  const [flavourName, setflavourName]:any = useState([]);

  const VeiwFunction = async (id: number) => {
    const result = await apiResponseGenerator(
      {
        dispatchFunction: dispatch,
        method: "get",
        url: `productflavors/get/${id}`
      }
    );
    if (result) {
      let responseProduct = result.data.product;
      let responseFlavors = result.data.flavors;

      setflavourName(responseFlavors);

      responseProduct.map(
        ({
          productSKU,
          productName,
          product_image,
          status,
          productDescription,
          volume
        }: any) => {
          setproductName(productName);
          setproductSKU(productSKU);
          setproductImage(product_image);
          setVolume(volume);
          setstatus(status);
          setproductDescription(productDescription);
        }
      );
    }
  };

  useEffect(() => {
    VeiwFunction(id);
  }, []);

  return loading ? (
    <>
      <div>
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "250px" }}
        >
          <ScaleLoader color="#007bff" height={40} />
        </div>
      </div>
    </>
  ) : (
    <>
      <ContentHeader title="View Product Categories" />
      <section className=" bg-white p-4 rounded m-3 ">
        <Image
          productName={productName}
          volume={volume}
          productImage={productImage}
          productBarcode={productSKU}
          status={status}
        />
        <ProductDescriptions productDescription={productDescription} />
        <Flavors flavourName={flavourName} />

        <div className="d-flex flex-row-reverse">
          <AppButton
            children={"Back"}
            className="btn btn-primary"
            onClick={() => {
              navigate("/admin/products/categories");
            }}
          />
        </div>
      </section>
    </>
  );
}

export default VeiwProducts;
