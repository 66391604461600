// export const BASE_URL="http://localhost:8000/api/v1/"
// export const BASE_URL="https://fs.ace-inventory.com/backend/api/v1/"
// export const BASE_URL="https://staging.ace-inventory.com/backend/api/v1/"
export const BASE_URL="https://staging-dev.ace-inventory.com/poshbackend/api/v1/"
// export const BASE_URL="http://192.168.0.112:8000/api/v1/"

export const ACE_PLATFORM = "B"

export const BLOCKED_PATHS = {
    B: [
        "/admin/products/categories",
        "/admin/products/categories/add",
        "/admin/products/categories/edit/:id",
        "/admin/products/categories/veiw/:id",
        "/admin/products/flavors",
        "/admin/products/flavors/add",
        "/admin/products/flavors/edit",
        "/admin/purchase-and-orders",
        "/admin/purchase-and-orders/shipment-receivings",
        "/admin/purchase-and-orders/new-shipments",
        "/admin/purchase-and-orders/view-shipments",
        "/admin/purchase-and-orders/edit-shipments",
        "/admin/user-and-role/users",
        "/admin/user-and-role/users/add",
        "/admin/user-and-role/users/edit",
        "/admin/user-and-role/userroles/edit",
        "/admin/user-and-role/userroles/view",
        "/admin/user-and-role/userroles/add",
        "/admin/user-and-role/company-details",
        "/admin/user-and-role/userroles",
        "/admin/shipping-agents",
        "/admin/shipping-agents/add",
        "/admin/shipping-agents/amounts",
        "/admin/shipping-agents/update",
        "/admin/code-system/formation",
        "/admin/code-system/formation/add",
        "/admin/code-system/formation/edit/:id",
        "/admin/code-system/code-generation",
        "/admin/code-system/code-generation/add",
        "/admin/code-system/code-generation/edit/:id",
        "/admin/reportings",
    ],
    A: [""]
}

export const BLOCKED_MENU = {
    B: [
        "Products",
        "Product Categories",
        "Flavors",
        "Purchase Orders",
        "Shipment & Receiving",
        "Users & Roles",
        "Shipping Agents",
        "Code System",
        "Formation",
        "Code Generation",
        "Reports",
    ],
    A: [""]
}