import React from 'react';
import {BASE_URL} from '../components/constants/app';
// import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import {progressLoader} from '@app/store/reducers/ui';
import {IApiResponseGenerator} from '../interface';
import swal from 'sweetalert';

const apiResponseGenerator = async ({
  dispatchFunction,
  method,
  url,
  body,
  successMsg,
  TimeOut
}: IApiResponseGenerator) => {
  if (!dispatchFunction) {
    alert('Please provide dispatch function');
    return;
  }

  const token = JSON.parse(localStorage.getItem('userInfo') || '{}')?.token;
  const config = {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`
  };

  // variables for  arguments
  const apiMethod: string = method ? method.toLocaleLowerCase() : 'get';
  const successMessage = successMsg
    ? successMsg
    : 'Action Perform Successfully';

  let loadingState = false;

  // variables for an api call
  let apiPayload: any = body;
  let axiosBody: any;
  let response: any = null;

  const apiWithBody = ['post', 'put', 'patch'];
  const apiWithoutBody = ['get', 'delete'];

  let loading = false;

  axiosBody = {
    url: `${BASE_URL}${url}`,
    headers: config,
    method: apiMethod,
    onUploadProgress: (progressEvent: any) => {
      let progressB = (progressEvent.loaded / progressEvent.total) * 100;
      dispatchFunction(progressLoader(progressB));
    },
    onDownloadProgress(progressEvent: {loaded: number; total: number}) {
      let progressB = (progressEvent.loaded / progressEvent.total) * 100;
      dispatchFunction(progressLoader(progressB));
    }
  };

  if (apiMethod && apiWithBody.includes(apiMethod) && !body) {
    alert('Please provide body for this method');
    return;
  }

  if (apiPayload) {
    if (apiWithoutBody.includes(apiMethod)) {
      alert('The body is not allowed for this method');
      return;
    }
    axiosBody.data = apiPayload;
  }

  if (TimeOut) {
    axiosBody.timeout = TimeOut;
  }

  try {
    dispatchFunction(progressLoader(15));
    loading = true;
    response = await axios(axiosBody);

    if (!response) {
      loading = false;
    }

    successMsg &&
      swal({
        title: 'Success',
        text: successMessage,
        icon: 'success',
        closeOnClickOutside: false,
        closeOnEsc: false
      });
  } catch (error: any) {
    dispatchFunction(progressLoader(100));
    loading = true;

    console.log({response});

    swal({
      title: 'Woops! something went wrong',
      text: `${
        error.response.data.data.message || error.response.data.message
      }`,
      icon: 'error',
      closeOnClickOutside: false,
      closeOnEsc: false,
      dangerMode: true
    });
  }

  console.log({response})
  if (response) {
    return response.data;
  }
  //  else {
  //   throw new Error('Api Error');
  // }
};

const handleRequestStatus = () => {};

export default apiResponseGenerator;
