import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {useSelector} from 'react-redux';

const PublicRoute = () => {
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const isAuthenticated = isLoggedIn || JSON.parse(localStorage.getItem('userInfo') || "{}")?.token;
  return isAuthenticated ? <Navigate to="/admin" /> : <Outlet />;
};

export default PublicRoute;
