import { logoutUser } from '@app/store/reducers/auth';
import React from 'react'
import { useEffect } from "react"
import {useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';



function Logout() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        logout() 
      }, [])
      
      const logout = () => {
        dispatch(logoutUser())
        navigate("/login")
      }
      
  return (
    <>

    </>
  )
}

export default Logout