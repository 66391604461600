import React, { useEffect, useState } from 'react'
import { useDispatch} from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AppButton from '../button/Button'
import ContentHeader from '../content-header/ContentHeader'
import PoshInputs from '../Forms/InputForms/Inputs'
import "./Addagensts.css"
import swal from 'sweetalert'
import { ActiveDropdown } from '../dropdown/ActiveDropdown'
import apiResponseGenerator from '@app/utils/apiResponseGenerator'

function AddAgents() {
  const navigate = useNavigate()
  const [state,setState]=useState({
    firstName:"",
    lastName:"",
    email:"",
    phone:"",
    address:"",
    status:1
  })
  const [disabled,setDisabled]=useState(true)
  const dispatch=useDispatch()
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(()=>{
      if(state.firstName  && state.lastName && emailRegex.test(state.email)  && state.phone && state.address){
        setDisabled(false)
      }else{
        setDisabled(true)
      }
  },[state])


  const addAgent=async()=>{
      swal({
        title: "Are you sure ?",
        text: "You want to add agent ?",
        icon: "warning",
        buttons: [true,true],
        dangerMode: true,
        closeOnClickOutside: false
      }).then(async(AddProduct)=>{ 
        if (AddProduct){
          let data ={
            firstName:state.firstName,
            lastName:state.lastName,
            email:state.email,
            phone:state.phone,
            address:state.address,
            status:state.status
          }
          const result = await apiResponseGenerator({dispatchFunction: dispatch, method: "post", body:data ,url: "agents/add"});
          if (result) {
            navigate("/admin/shipping-agents")
          }
        }  
      })
  }
  return (
    <div>
     <ContentHeader title="Add Agents" />
      <section className=" bg-white p-4 rounded m-3">
        <div>
            <div className="inputWrapper">
              <PoshInputs validate={"*"} value={state.firstName} onChange={(e:any)=>{setState({...state,firstName:e.target.value})}}   title='First Name' type={"text"} readOnly={true} />
              <PoshInputs validate={"*"} value={state.lastName} onChange={(e:any)=>{setState({...state,lastName:e.target.value})}}  title='Last Name' type={"text"} readOnly={true} />
            </div>

            <div className="inputWrapper">
              <PoshInputs validate={"*"} value={state.email} onChange={(e:any)=>{setState({...state,email:e.target.value})}}  title='Email' type={"email"} readOnly={true} />
              <PoshInputs validate={"*"} value={state.phone} hideControl onChange={(e:any)=>{setState({...state,phone:e.target.value})}} title='Phone' type={"number"} readOnly={true} />
            </div>

            <div className="inputWrapper">
              <PoshInputs validate={"*"} value={state.address}  onChange={(e:any)=>{setState({...state,address:e.target.value})}}  title='Address' type={"text"} readOnly={true} />
              <ActiveDropdown validate={"*"} title='Status' onChange={(e:any)=>{setState({...state,status:e.target.value})}}   />
            </div>

            <div className="d-flex justify-content-end" style={{marginRight:"10px"}}>
              <AppButton children={"Cancel"} onClick={()=>{navigate("/admin/shipping-agents")}} className="btn btn-light mr-3"/>
              <AppButton disabled={disabled} onClick={()=>{addAgent()}}  children={"Add"} className="btn btn-primary"/>
            </div> 

        </div>
      </section>
    </div>
  )
}

export default AddAgents