import React,{useState} from 'react'
import ContentHeader from '../content-header/ContentHeader'

function OrderDetails() {
    const [users, setUsers] = useState([
       
        { "s_no": 1,"order_no":"#5312" , "Date":"5/08/2021"},
        { "s_no": 2,"order_no":"#5312" , "Date":"5/08/2021"},
        { "s_no": 3,"order_no":"#0312" , "Date":"10/08/2021"},
        { "s_no": 4,"order_no":"#0312" , "Date":"1/08/2021"},
        { "s_no": 5,"order_no":"#0312" , "Date":"1/08/2021"},
        { "s_no": 6,"order_no":"#5655" , "Date":"15/08/2021"}
      ])
  return (
    <div>
        <ContentHeader title="Order Details" />
        <section className=" bg-white p-4 rounded m-3">
        <div className="table-responsive-xxl" >
         <table className="table table-hover">
           <thead className="thead- bg-primary">
             <tr>
               <th scope="col">S.No.</th>
               <th scope="col">Products</th>
               <th scope="col">Flavors</th>
               <th scope="col">Quantity</th>
               <th scope="col">Amount</th>
             </tr>
           </thead>
           <tbody>
             {
             users.map((user:any) => {
               return (
                 <tr>
                   <td >{user.s_no}</td>
                   <td >{user.order_no}</td>
                   <td >{user.order_no}</td>
                   <td >{user.s_no}</td>
                   <td >{user.Date}</td>                                  
                  </tr>
               )
              })
            }
           </tbody>
         </table>
         </div>
        </section>
    </div>
  )
}

export default OrderDetails